<template>
  <form @submit.prevent="submit" class="pt-2 pr-4 pb-2 pl-4 purchase-ht1">
    <div class="row purchase-ht4">
      <div class="col-md-12 pb-1">
        <div class="row">
          <div class="col-md-10 pl-0">
            <h4 class="content-title content-title-xs">New Stock Transfer</h4>
          </div>
          <div class="col-md-2 float-right text-right pr-0 pl-5">
            <select
              v-model="formData.branch"
              class="form-control right-select-drop"
              :disabled="branchDisabled"
              @change="getDataByBranch(formData.branch)"
            >
              <option value>Select Branch</option>
              <option
                v-for="(branch, index) in dataSets.branches"
                :key="index"
                :value="branch.id"
              >
                {{ branch.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-md-3 bg-theam-secondary mx-0 p-2">
        <div class="stock-transfer-ht">
          <div>
            <span class="title-supplier">Transferring Branch</span>
          </div>
          <div class="input-group py-2">
            <button type="button" class="btn-primary input-group-text">
              <i class="fa fa-user"></i>
            </button>
            <select v-model="formData.branch_to" class="form-control" required>
              <option value>Select Branch</option>
              <option
                v-for="(branch, index) in dataSets.toBranches"
                :key="index"
                :value="branch.id"
              >
                {{ branch.name }}
              </option>
            </select>
          </div>
          <div class="row">
            <div class="col-md-5 mr-0 pr-0">
              <label class="page-text">Date</label>
            </div>
            <div class="col-md-7 ml-0 pl-0">
              <input
                type="date"
                class="form-control"
                v-model="formData.date"
                required
              />
            </div>
          </div>
          <div class="row py-2">
            <div class="col-md-5 mr-0 pr-0">
              <label class="page-text">Transfer ID</label>
            </div>
            <div class="col-md-7 ml-0 pl-0">
              <input
                type="text"
                class="form-control"
                v-model="formData.transfer_id"
                placeholder
                disabled
              />
            </div>
          </div>
        </div>
        <div class>
          <div class="row">
            <div class="col-md-7 mr-0 pr-0">
              <button
                class="btn btn-xs btn-primary"
                type="button"
                @click="$store.dispatch('modalOpen', 'shippingDetils')"
              >
                Shipping Detail
              </button>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-md-5 mr-0 pr-0">
              <label class="page-text">Responsible</label>
            </div>
            <div class="col-md-7 ml-0 pl-0">
              <input
                type="text"
                v-model="formData.responsible_person"
                class="form-control"
                placeholder="Person Name"
              />
            </div>
          </div>
          <div class="row pt-2">
            <div class="col-md-5 mr-0 pr-0">
              <label class="page-text">Contact No.</label>
            </div>
            <div class="col-md-7 ml-0 pl-0">
              <input
                type="text"
                class="form-control"
                v-model="formData.responsible_person_contact"
                placeholder="98XXXXXXXX"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-9 mx-0 pl-2 pr-0 purchase-ht2">
        <div class="bg-theam-secondary p-2">
          <div class="row pt-0">
            <div class="col-md-4 mr-0 pr-0">
              <div class="page-text">Item</div>
              <div class="input-group">
                <div class="input-group-prepend">
                  <button
                    type="button"
                    class="btn-primary input-group-text"
                    id="product"
                  >
                    <i class="fas fa-briefcase"></i>
                  </button>
                </div>
                <input
                  type="text"
                  class="form-control"
                  v-model="items.name"
                  placeholder="Items"
                  data-toggle="modal"
                  data-target="#transferItemList"
                  @click="openTransferitemComponent()"
                  readonly
                />
              </div>
            </div>

            <div
              class="col-md-4 mr-0 pr-0"
              v-if="dataSets.item_setting.barcode == 1"
            >
              <div class="page-text">Barcode</div>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="barcode">
                    <i class="fas fa-barcode"></i>
                  </span>
                </div>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Barcode"
                  @blur="getDataByBarcode"
                  v-model="items.barcode"
                />
              </div>
            </div>
            <div class="col-md-3">
              <div class="page-text">Expiry Date</div>
              <div class="input-group">
                <input
                  type="date"
                  class="form-control"
                  placeholder="Supplier Name"
                  v-model="items.expiry_date"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2 mr-0 pr-0 pt-2 zi-0">
              <div class="page-text">Stock *</div>
              <div class="input-group">
                <input
                  type="number"
                  step="any"
                  class="form-control"
                  placeholder="0"
                  v-model="items.available"
                  disabled
                />
                <div class="input-group-prepend">
                  <span
                    class="input-group-text"
                    style="background: #8db8ee; color: black"
                    >{{ items.unit_type }}</span
                  >
                </div>
              </div>
            </div>
            <div class="col-md-2 mr-0 pr-0 pt-2 zi-0">
              <div class="page-text">Quantity *</div>
              <div class="input-group">
                <input
                  type="number"
                  step="any"
                  class="form-control"
                  placeholder="0"
                  v-model.trim="items.quantity"
                  @keyup="calculateItemTotal()"
                  @change="calculateItemTotal()"
                  @keydown.tab.exact="
                    options.edit == true ? updateItem() : addItem()
                  "
                />
                <div class="input-group-prepend">
                  <span
                    class="input-group-text"
                    id="quantity"
                    style="background: #8db8ee; color: black"
                    >{{ items.unit_type }}</span
                  >
                </div>
              </div>
            </div>
            <div class="col-md-2 mr-0 pr-0 pt-2 zi-0">
              <div class="page-text">A. Purchase Rate *</div>
              <div class="input-group">
                <input
                  type="number"
                  step="any"
                  class="form-control"
                  disabled
                  placeholder="0"
                  v-model="items.actual_purchase_rate"
                />
              </div>
            </div>
          </div>
          <div class="row pt-2">
            <div class="col-md-1 mr-0 pr-0">
              <label class="page-text" for="date">Total</label>
            </div>
            <div class="col-md-2 ml-0 pl-0">
              <input
                v-model="items.total"
                class="form-control"
                disabled
                type="number"
              />
            </div>
            <div class="col-md-9 d-flex justify-content-end">
              <div v-if="options.edit">
                <div
                  class="btn btn-success btn-xs"
                  type="button"
                  @click="updateItem"
                >
                  Update Item
                </div>
                <div
                  class="btn btn-danger btn-xs ml-2"
                  type="button"
                  @click="closeUpdate()"
                >
                  Close
                </div>
              </div>
              <div
                v-else
                class="btn btn-success btn-xs"
                type="button"
                @click="addItem"
              >
                Add Item
              </div>
            </div>
          </div>
          <!-- <div class="row pt-2">
            <div class="col-md-12 d-flex justify-content-end">
              <div v-if="options.edit">
                <div class="btn btn-success btn-xs" @click="updateItem" type="button">
                  Update Item
                </div>
                <div class="btn btn-danger btn-xs ml-2" @click="options.edit = false" type="button">
                  Close
                </div>
              </div>
              <div v-else class="btn btn-success btn-xs" @click="addItem" type="button">
                Add Item
              </div>
            </div>
          </div>-->
        </div>
        <div class="bg-theam-secondary mt-2 purchase-ht3 purchase-table">
          <table class="table table1">
            <thead>
              <tr>
                <th scope class="wd-5p table-start-item">S.N</th>
                <th scope class="wd-65p">Particular</th>
                <th scope class="wd-20p">Qty</th>
                <th scope class="wd-20p">Total</th>
                <th scope class="wd-10p text-right table-end-item">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in formData.items" :key="index">
                <th scope="row" class="table-start-item">{{ ++index }}</th>
                <td>{{ item.name }}</td>
                <td>{{ item.quantity }} {{ item.unit_type }}</td>
                <td>{{ parseDigit(item.total) }}</td>
                <td class="text-right table-end-item">
                  <a href="javascript:;" class="mr-2" @click="editItem(index)">
                    <i class="fa fa-edit"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="mr-0"
                    @click="removeItem(index)"
                  >
                    <i class="fa fa-trash"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-md-12 bg-theam-secondary p-2 mt-2">
        <div class="row">
          <div class="col-10">
            <div class="row">
              <div class="col-md-3 pr-0">
                <div class="page-text">Note</div>
                <textarea
                  name="note"
                  class="form-control page-note"
                  v-model="formData.note"
                ></textarea>
              </div>
              <div class="col-md-2 mr-0 pr-0">
                <div class="page-text">Grand Total</div>
                <div class="input-group">
                  <input
                    v-model="formData.total"
                    class="form-control"
                    disabled
                    placeholder="0.00"
                    step="any"
                    type="number"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="row justify-content-end mr-0">
              <button
                class="col-7 btn btn-success btn-sm btn-min-wd"
                type="submit"
                @click.prevent="submitData()"
                :disabled="submitting || loading"
              >
                <span v-if="loading">Loading...</span>
                <span v-else-if="!submitting">Submit</span>
                <span v-else>Submitting...</span>
              </button>
              <button
                class="col-7 btn btn-warning btn-sm btn-min-wd my-2"
                type="button"
                @click.prevent="reset()"
              >
                Reset
              </button>
              <button
                class="col-7 btn btn-danger btn-sm btn-min-wd"
                tag="button"
                v-if="checkSinglePermission('view_stock_transfer')"
                @click.prevent="cancel()"
              >
                Cancel
              </button>
            </div>
          </div>

          <!--  -->
          <!-- <div class="col-md-2 offset-md-5 text-right">
            <button
              type="submit"
              class="btn btn-success btn-sm btn-min-wd"
              @click.prevent="submitData()"
              :disabled="submitting || loading"
            >
              <span v-if="loading">Loading...</span>
              <span v-else-if="!submitting">Submit</span>
              <span v-else>Submitting...</span>
            </button>
            <button
              type="button"
              class="btn btn-warning btn-sm btn-min-wd my-2"
              @click.prevent="reset()"
            >Reset Item</button>
            <button
              type="button"
              class="btn btn-danger btn-sm btn-min-wd"
              @click.prevent="cancel()"
            >Cancel</button>
          </div>-->
        </div>
      </div>
    </div>
    <!--   Shipping Details modals-->
    <div class="col-md-12">
      <div
        v-if="modalId == 'shippingDetils'"
        aria-hidden="true"
        class="modal fade modal_cust show"
        role="dialog"
        style="display: block"
        tabindex="-1"
      >
        <div
          class="
            modal-dialog modal-dialog-centered modal_ac_head
            single-column-popup
          "
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5 id="exampleModalCenterTitle" class="modal-title">
                Add Shipping Detial
              </h5>
              <a
                class="close"
                @click="$store.dispatch('modalClose', 'supplierList')"
                title="Close"
              >
                <span aria-hidden="true">×</span>
              </a>
            </div>
            <div class="bg-theam-secondary">
              <div class="modal-body row">
                <div class="col-md-12">
                  <div class="group">
                    <label class="group-label">Details</label>
                    <div class="group-attribute-container">
                      <div class="row mb-2">
                        <div class="col-md-5 mr-0 pr-0">Driver</div>
                        <div class="col-md-7 ml-0 pl-0">
                          <input
                            v-model="formData.driver"
                            class="form-control"
                            placeholder="Driver Name"
                          />
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-md-5 mr-0 pr-0">Vehicle</div>
                        <div class="col-md-7 ml-0 pl-0">
                          <input
                            v-model="formData.vehicle"
                            class="form-control"
                            placeholder="vehicle number"
                            type="input"
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-5 mr-0 pr-0">Contact</div>
                        <div class="col-md-7 ml-0 pl-0">
                          <input
                            v-model="formData.contact"
                            class="form-control"
                            placeholder="9840000000"
                            type="input"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 text-right mt-3">
                  <button
                    type="submit"
                    class="btn btn-sm btn-primary"
                    @click="$store.dispatch('modalClose', 'shippingDetils')"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <TransferItemComponent
      @clicked="componentItemClicked"
      ref="transferItemComponent"
    ></TransferItemComponent>
    <TransferSlip @printSlip="printSlip" />
  </form>
</template>

<script>
import { mapGetters } from "vuex";
import TransferItemComponent from "./Componenets/TransferItemComponent";
import Services from "./Services/Service";
import TransferSlip from "./transferSlip";

export default {
  components: {
    TransferItemComponent,
    TransferSlip,
  },
  data() {
    return {
      options: {
        edit: false,
        index: null,
      },
      branchDisabled: false,
      dataSets: {
        items: [],
        branches: [],
        toBranches: [],
        purchase_setting: [],
        item_setting: [],
      },
      submitting: false,
      loading: true,
      formData: {
        total: 0,
        branch: "",
        branch_to: "",
        date: null,
        transfer_id: "",
        driver: "",
        vehicle: "",
        contact: "",
        responsible_person: "",
        responsible_person_contact: "",
        note: null,
        items: [],
      },
      items: {
        unit_type: null,
        vat_applicable: null,
        item_id: null,
        name: null,
        available: 0,
        quantity: 0,
        sales_rate: null,
        sales_discount_percentage: null,
        sales_discount_amount: null,
        total: 0,
        barcode: null,
        old_barcode: null,
        expiry_date: null,
      },
    };
  },
  computed: {
    ...mapGetters([
      "icons",
      "today",
      "dataId",
      "dataLists6",
      "dataLists7",
      "dataLists8",
      "dataLists9",
      "removeList",
      "pagination",
      "eventMessage",
      "modalId",
      "today",
    ]),
    ...mapGetters("stockTransfer", [
      "itemSearchLoading",
      "itemGroups",
      "itemsByBranch",
      "branchId",
    ]),
  },
  mounted() {
    this.formData.date = this.today;
    this.getData();
    // JSON.parse(localStorage.getItem("items"))
  },
  methods: {
    getData() {
      Services.getDataForStockTransferCreation()
        .then((response) => {
          let value = response.data.data;
          this.$store.commit("setDataLists6", value);
          this.loading = false;
          this.dataSets.branches = value.branches;
          this.dataSets.toBranches = value.to_branches;
          this.dataSets.sales_setting = value.sales_setting;
          this.dataSets.item_setting = value.item_setting;
          this.formData.transfer_id = value.transferId;
          this.$store.commit("stockTransfer/setItemGroups", value.item_groups);
          // checking if there is only one branch
          if (this.dataSets.branches.length == 1) {
            this.branchDisabled = true;
            this.formData.branch = value.branches[0]["id"];
            this.getDataByBranch(this.formData.branch);
          }
          // let data = JSON.parse(localStorage.getItem('stockTransferFormData'));
          // if(data){
          //   this.formData.note  = data.note;
          // }
        })
        .catch((error) => {
          this.loading = false;
          if (
            !Object.prototype.hasOwnProperty.call(
              error,
              "response.data.error_message"
            )
          ) {
            if (error.response.data.error_message == "Access Denied.") {
              this.errorMessage = "Access Denied !!!";
              this.$router.push({ name: "access-denied" });
            } else {
              this.errorMessage = "Error fetching data from the server.";
              setTimeout(() => {
                this.setNotification(this.errorMessage);
              }, 600);
              this.$router.push({ name: "transfer-item-list" });
            }
          }
        });
    },

    getDataByBranch(branchId) {
      this.items = {};
      this.itemsByBranch.items = [];
      this.$store.commit("stockTransfer/setItemSearchLoading", true);
      this.itemExpired = false;
      localStorage.removeItem("stockTransferPaymentMethod");
      Services.getItemsByBranch(branchId)
        .then((response) => {
          let value = response.data.data;
          this.$store.commit("stockTransfer/setItemsByBranch", value);
          this.$store.commit("stockTransfer/setBranchId", branchId);
          this.dataSets.items = value.items;
          // let localData = JSON.parse(localStorage.getItem('stockTransferFormData'));
          // console.log(localData);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.$store.commit("stockTransfer/setItemSearchLoading", false);
        });
    },

    openTransferitemComponent() {
      if (this.formData.branch) {
        this.$store.dispatch("modalWithMode", {
          id: "transferItemList",
          mode: "create",
        });
        setTimeout(() => {
          this.$nextTick(() => {
            this.$refs.transferItemComponent.$refs.itemSearch.focus();
          });
        }, 600);
      } else {
        this.setNotification("Please select branch first.");
      }
    },
    // receiving data from child component i.e SalesItemComponent
    componentItemClicked(details) {
      this.items = [];
      if (details["branch"] == null && details["purchase_id"] == null) {
        details["available"] = 1;
        details["quantity"] = 1;
        this.itemStockable = false;
      } else {
        this.itemStockable = true;
      }
      this.items = { ...details };
      this.items.purchase_id = details.purchase_id;
      this.items.item_id = details.item.id;
      this.items.name = details.item.name;
      this.items.unit_type = details.item.unit_type.title;
      this.items.vat_applicable = details.item.vat_applicable;
      this.items.quantity = 1;
      this.items.total = details.actual_purchase_rate;
      this.items.expiry_date = details.expiry_date;
      this.items.old_barcode = details.barcode;
      // Generating the new barcode for the transfer item
      this.items.barcode = Date.now() + Math.floor(Math.random() * 10);
      this.formData.date = this.today;
      if (details.expiry_date) {
        let curretnDateInMiliSecond = new Date().getTime();
        let itemExptime = new Date(this.items.expiry_date);
        let itemExpTimeinMiliSecond = new Date(itemExptime).getTime();

        if (curretnDateInMiliSecond > itemExpTimeinMiliSecond) {
          this.itemExpired = true;
        } else {
          this.itemExpired = false;
        }
      } else {
        this.itemExpired = false;
      }
    },

    getDataByBarcode() {
      let branchId = this.formData.branch;
      if (!this.items.name) {
        if (branchId) {
          Services.getDataByBarcode(this.items.barcode, branchId)
            .then((response) => {
              let value = response.data.data;
              if (value) {
                this.items = { ...value };
                this.items.item_id = value.item.id;
                this.items.name = value.item.name;
                this.items.quantity = 1;
                this.items.total = value.actual_purchase_rate;
              } else {
                this.setNotification("No product Found");
              }
            })
            .catch((error) => {
              if (error.response.status == 500) {
                let err = error.response.data.error_message;
                this.setNotification(err);
              }
            });
        } else {
          this.setNotification("Please select branch first.");
        }
      }
    },

    calculateItemTotal() {
      if (
        parseFloat(this.items.quantity || 0) >
        parseFloat(this.items.available || 0)
      ) {
        this.items.quantity = this.items.available;
      }
      if (
        parseFloat(this.items.quantity || 0) >= 0 &&
        parseFloat(this.items.quantity) !== null
      ) {
        this.items.total = this.parseDigit(
          this.items.quantity * this.items.actual_purchase_rate
        );
      } else {
        this.items.total = 0;
      }
    },
    addItem() {
      if (this.itemValidation()) {
        if (this.formData.items.length > 0) {
          let item_id = this.items.item_unique_id;
          let filteredItem = this.formData.items.find(function (data) {
            return data.item_unique_id == item_id;
          });
          if (filteredItem == undefined) {
            this.formData.items.push({ ...this.items });
            // to disable the branch selection
            this.branchDisabled = true;
            // to change the color of date if expired
            this.itemExpired = false;
            // to clear the item fields
            this.clearItem();
            this.itemStockable = true;
            // this.addFormDataToLocalStorage(this.formData);
          } else {
            let err = `${this.items.name} already in purchased list.`;
            this.setNotification(err);
            this.clearItem();
          }
        } else {
          this.formData.items.push({ ...this.items });
          this.branchDisabled = true;
          this.itemExpired = false;
          this.itemStockable = true;
          this.clearItem();
        }
        this.calculateTotal();
      }
    },
    itemValidation() {
      if (!this.items.item_id) {
        this.setNotification("Please select item first.");
        return false;
      }
      if (this.items.quantity <= 0) {
        this.setNotification("Quantity must be greater than 0.");
        return false;
      }

      return true;
    },
    removeItem(index) {
      this.$swal({
        title: "Do you really want to remove this item ?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          this.formData.items.splice(index - 1, 1);
          if (this.formData.items.length == 0) {
            this.branchDisabled = false;
          }
          this.calculateTotal();
          // this.addFormDataToLocalStorage(this.formData);
        }
      });
    },
    editItem(index) {
      this.items = { ...this.formData.items[index - 1] };
      if (this.items["validity"] == "Expired") {
        this.itemExpired = true;
      } else {
        this.itemExpired = false;
      }
      this.options.edit = true;
      this.options.index = [index - 1];
    },
    updateItem() {
      if (this.itemValidation()) {
        let dataAdded = false;
        let currentAddedItems = this.formData.items;
        currentAddedItems.forEach((el) => {
          if (el.item.id == this.items.item.id) {
            dataAdded = true;
            if (this.formData.items.length < this.options.index) {
              this.formData.items.push({ ...this.items });
            } else {
              this.formData.items[this.options.index] = { ...this.items };
            }
          }
        });
        if (dataAdded == false) {
          this.formData.items.push({ ...this.items });
        }
        this.items = [];
        this.options.edit = false;
        this.itemExpired = false;
        this.calculateTotal();
      }
    },
    closeUpdate() {
      this.options.edit = false;
      this.items = [];
      this.itemExpired = false;
    },
    submitData() {
      if (this.formValidation()) {
        this.submitting = true;
        Services.storeTransferItem(this.formData)
          .then((response) => {
            this.reset("stock");
            //making array because transferSlip always accepts an array of data
            let transferItem = [];
            transferItem.push(response.data.data.transfer_item);

            this.$store.commit("stockTransfer/setDataSets", transferItem);
            this.$store.commit(
              "stockTransfer/setItemsByBranch",
              response.data.data.items
            );
            //poping up the purchaseSlip modal
            this.$store.commit(
              "setDataId",
              response.data.data.transfer_item.id
            );

            // providing the organization setting to transferSlip component
            this.$store.commit(
              "stockTransfer/setSlipSettings",
              response.data.data
            );

            this.$store.dispatch("modalWithMode", {
              id: "transferSlip",
              mode: "read",
            });
          })
          .catch((error) => {
            //validation error

            console.log(error);
            if (error.response.status == 422) {
              let err = Object.values(error.response.data.errors).flat();
              this.setNotification(err);
            } else {
              let err = error.response.data.error_message;
              this.setNotification(err);
            }
          })
          .finally(() => {
            this.submitting = false;
          });
      }
    },
    printSlip(id) {
      //once the modal is opened popup print window
      window.print(id);
    },
    formValidation() {
      if (!this.formData.branch) {
        this.setNotification("Please select branch first.");
        return false;
      }
      if (!this.formData.branch_to) {
        this.setNotification("Please select transferring branh.");
        return false;
      }
      if (this.formData.branch == this.formData.branch_to) {
        this.setNotification("Cannot transfer to same branch");
        return false;
      }
      if (this.formData.items.length == 0) {
        this.setNotification("Please Add item first.");
        return false;
      }
      return true;
    },
    calculateTotal() {
      this.formData.total = 0;
      this.formData.items.forEach((el) => {
        this.formData.total += parseFloat(el.total);
      });
      this.formData.total = this.parseDigit(this.formData.total);
    },
    clearItem() {
      this.items.vat_applicable = null;
      this.items.item_id = null;
      this.items.name = null;
      this.items.available = 0;
      this.items.quantity = 0;
      this.items.sales_rate = 0;
      this.items.sales_discount_percentage = 0;
      this.items.sales_discount_amount = 0;
      this.items.total = 0;
      this.items.actual_purchase_rate = 0;
      this.items.barcode = null;
      this.items.old_barcode = null;
      this.items.expiry_date = null;
      this.items.unit_type = null;
    },
    setNotification(err) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", err);
    },
    addFormDataToLocalStorage(value) {
      localStorage.setItem("stockTransferFormData", JSON.stringify(value));
      this.$store.commit("stockTransfer/setBranchId", this.formData.branch);
    },
    reset(type = null) {
      this.formData.total = 0;
      if (this.dataSets.branches.length == 1) {
        this.branchDisabled = true;
        if (type == "stock") {
          this.formData.transfer_id = new Date().getTime();
        }
      } else {
        this.branchDisabled = false;
      }
      if (type == "stock") {
        this.formData.transfer_id = new Date().getTime();
      }
      this.formData.branch_to = "";
      this.formData.date = this.today;
      this.formData.drvier = "";
      this.formData.vehicle = "";
      this.formData.contact = "";
      this.formData.responsible_person = "";
      this.formData.responsible_person_contact = "";
      this.formData.note = null;
      this.formData.items = [];
      this.clearItem();
    },
    cancel() {
      this.$router.push({ name: "transfer-item-list" });
    },
  },
  watch: {
    eventMessage(value) {
      if (value.indexOf("transfer successfully") >= 0) {
        this.$router.push("/inventory/transfer");
      }
    },
  },
  destroyed() {
    this.$store.dispatch("destroyEvent");
    localStorage.removeItem("stockTransferFormData");
  },
};
</script>
<style scoped>
.form-control-custom h6 {
  font-weight: 600;
}
.right-select-drop {
  background: #1b52bf !important;
  color: #f4f7fa !important;
  border-radius: 5px !important;
  height: 1.4rem !important;
  font-size: 0.75rem;
}
.input-group-text {
  background: #1b52bf;
  color: #f4f7fa;
  z-index: 20;
}
.input-group-text {
  outline: none;
}
input,
select,
textarea {
  background: #f4f7fa;
}
input:focus,
select:focus,
textarea:focus {
  background: #ffffff;
}
.form-control:disabled {
  background: #9ac3f0 !important;
  color: black !important;
}
</style>
